import * as React from 'react';
import { AppRootProps } from '@grafana/data';
import './App.css';

export class App extends React.PureComponent<AppRootProps> {
    render() {
      return (
        <div
            className="wideSky-page-container"
            style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img
                className=""
                src="https://widesky.cloud/wp-content/uploads/2020/04/WideSky-watermark-dark.svg"
                alt="WideSky"
                style={{ width: '150px' }}
            />
        </div>
    );
}

}
